import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { postProcessAxiosError } from '../base-api';
import { PATH_TO_CORE_API } from '@shared/constants';

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_CORE_API,
};

export interface DeviceBindingInfoDto {
  deviceSerialNumber: string;
  deviceBrandName: string;
  deviceFirmwareVersion: string;
  isTelemonitoringEnabled: boolean;
  patientPrismaCloudId: string | null;
  patientFirstName: string | null;
  patientLastName: string | null;
  ownerOrganisationName: string;
  ownerOrganisationTenant: string;
  unassignedAt: string | null;
  deactivatedAt: string | null;
  assignmentState: 'Assigned' | 'NotAssigned' | 'AssignmentNotPossible';
}

export const DeviceBindingsApi = {
  async getDeviceBindingWithPatientInfo(deviceBindingId: string, options?: AxiosRequestConfig) {
    try {
      const response: AxiosResponse<DeviceBindingInfoDto> = await axios.get(
        `/api/DeviceBindings/with-patient/${deviceBindingId}`,
        {
          ...defaultOpts,
          ...options,
        },
      );
      return response.data;
    } catch (e: any) {
      postProcessAxiosError(e);
    }
  },
};
