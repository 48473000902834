export enum PatientSortingFields {
  AdditionalId = 'AdditionalId',
  Name = 'Name',
  OverallRelevance = 'OverallRelevance',
  AvgAhi = 'AvgAhi',
  LeakageMedian = 'LeakageMedian',
  AvgUsage = 'AvgUsage',
  LastDataReceipt = 'LastDataReceipt',
  DateOfBirth = 'DateOfBirth',
}

export enum UserSortingFields {
  LastName = 'LastName',
  FirstName = 'FirstName',
  Email = 'Email',
  LastSeen = 'LastSeen',
  FullName = 'FullName',
}

export enum OrganisationSortingFields {
  Name = 'Name',
  Tenant = 'Tenant',
  Country = 'Country',
}

export enum DeviceSortingFields {
  SerialNumber = 'SerialNumber',
  LastDataReceivedAt = 'LastDataReceivedAt',
  AssignedPatient = 'AssignedPatient',
}

export type AssignDeviceSortField = 'serialNumber' | 'lastDataReceivedAt' | 'assignedPatient' | 'assignedUser';
