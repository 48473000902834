import { AxiosResponse } from 'axios';
import dayjs, { Dayjs, duration } from 'dayjs';

import { reportMessage } from './sentry';

/*
 * Explicitly uses YYYY-MM-DD format (ISO without time part), otherwise the date will be shifted to UTC time
 * which will e.g. lead to day shifting - selecting 15.12.2017 results in 14.12.2017 (at 11PM).
 * Using the format prevents it.
 */
export const getDateOnlyString = (dateTimeIso: Dayjs | string | Date | null) => {
  return dayjs(dateTimeIso).format('YYYY-MM-DD');
};

export const getDateOnlyDayjs = (dateTimeIso: Dayjs | string | Date) => {
  return dayjs(dateTimeIso, 'YYYY-MM-DD');
};

// There is a bug in dayjs https://github.com/iamkun/dayjs/issues/1189
// Here workaround: drop time part and compare only dates.
export function areSameDays(
  firstDate: Dayjs | string | Date,
  secondDate: Dayjs | string | Date | null | undefined,
  unit?: dayjs.OpUnitType,
): boolean;
export function areSameDays(
  firstDate: Dayjs | string | Date | null | undefined,
  secondDate: Dayjs | string | Date,
  unit?: dayjs.OpUnitType,
): boolean;
export function areSameDays(
  firstDate: Dayjs | string | Date | null | undefined,
  secondDate: Dayjs | string | Date | null | undefined,
  unit?: dayjs.OpUnitType,
) {
  if ((firstDate == null && secondDate != null) || (firstDate != null && secondDate == null)) {
    return false;
  }

  const adjustedFirstDate = getDateOnlyString(firstDate ?? null);
  const adjustedSecondDate = getDateOnlyString(secondDate ?? null);
  return dayjs(adjustedFirstDate).isSame(dayjs(adjustedSecondDate), unit);
}

export function* eachDayOfInterval(start: Dayjs | string | number | Date, end: Dayjs | string | number | Date) {
  let currentDate = dayjs(start);
  const endDate = dayjs(end);
  while (currentDate.isBefore(endDate)) {
    yield currentDate.toDate();
    currentDate = currentDate.add(1, 'day');
  }
  yield currentDate.toDate();
}

/**
 * Gets dates withing given date range (inclusive).
 * @param start Start date of interval.
 * @param end End date of interval.
 */
export const getDatesWithinDateRange = (start: Dayjs, end: Dayjs) => {
  // new Date(year, month, date) conversion is needed because we want to get dates
  // in scope of date range ignoring timezone conversion.
  // If create Date object from a string then it will be automatically converted in browser's timezone.
  // Example:
  //   new Date('2021-01-01') -> Thu Dec 31 2020 14:00:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  //   new Date(2021, 0, 1)   -> Fri Jan 01 2021 00:00:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  return Array.from(
    eachDayOfInterval(
      new Date(start.year(), start.month(), start.date()),
      new Date(end.year(), end.month(), end.date()),
    ),
  );
};

/**
 * Dayjs do not support .NET System.TimeSpan serialization format.
 * This function is workaround for Auth server, where it is impossible to use IsoTimeSpanConverter because result
 * Is in code of IdentityServer (cannot apply attribute, applied global converter is ignored).
 *
 * @param timeSpan .NET System.TimeSpan serialized.
 * @returns Dayjs date.
 */
export const timeSpanToDuration = (timeSpan: string) => {
  if (!timeSpan) {
    return undefined;
  }

  const invalidValue = { value: undefined, hasValue: false, rest: '' } as const;

  const parseDurationUnit = (numberString: string, rest: string) => {
    const value = parseInt(numberString);
    if (isNaN(value)) {
      return invalidValue;
    }

    return { value, hasValue: true, rest };
  };

  const extractLastTimeUnit = (timeSpan: string) => {
    const colonSeparator = timeSpan.lastIndexOf(':');
    if (colonSeparator === -1) {
      return invalidValue;
    }

    const numberString = timeSpan.substring(colonSeparator + 1);
    if (numberString.indexOf('.') !== -1) {
      return invalidValue;
    }

    return parseDurationUnit(numberString, timeSpan.substring(0, colonSeparator));
  };

  const parseMilliseconds = (rest: string) => {
    const lastDot = rest.lastIndexOf('.');
    const lastComma = rest.lastIndexOf(':');
    if (lastDot > lastComma) {
      // C# Timespan provides milliseconds as ticks (up to 7 digits) but JS accept ms max 3 digit
      // So simply parse first 3 digits of milliseconds.
      const msString = rest.substring(lastDot + 1, lastDot + 4);
      return parseDurationUnit(msString, rest.substring(0, lastDot));
    }
    return { value: 0, hasValue: true, rest };
  };

  const parseHoursAndDays = (rest: string) => {
    const hoursAndDays = rest.split('.');
    if (hoursAndDays.length > 2 || hoursAndDays.indexOf(':') !== -1) {
      return { days: invalidValue, hours: invalidValue };
    }

    if (hoursAndDays.length === 1) {
      return {
        days: { value: 0, hasValue: true, rest: '' },
        hours: parseDurationUnit(rest, ''),
      };
    }

    return {
      days: parseDurationUnit(hoursAndDays[0], ''),
      hours: parseDurationUnit(hoursAndDays[1], ''),
    };
  };

  const milliseconds = parseMilliseconds(timeSpan);
  const seconds = extractLastTimeUnit(milliseconds.rest);
  const minutes = extractLastTimeUnit(seconds.rest);
  const { days, hours } = parseHoursAndDays(minutes.rest);

  // Dayjs do not support negative duration see https://github.com/iamkun/dayjs/issues/1788
  // Simply return null to handle it.
  if (timeSpan[0] == '-') {
    return undefined;
  }

  if (!milliseconds.hasValue || !seconds.hasValue || !minutes.hasValue || !hours.hasValue || !days.hasValue) {
    return undefined;
  }

  const time = {
    years: 0,
    months: 0,
    days: days.value ?? 0,
    hours: hours.value ?? 0,
    minutes: minutes.value ?? 0,
    seconds: seconds.value ?? 0,
    milliseconds: milliseconds.value ?? 0,
  };

  return duration(time);
};

export const getServerDate = (response: AxiosResponse) => {
  const utcNow = new Date(response.headers.date);
  if (isNaN(utcNow.getTime())) {
    // If date from response header wasn't parsed correctly:
    //   1. Report an error to sentry to investigate possible problems.
    //   2. Report a generic error to user.
    reportMessage(`Date from response header was not parsed correctly, date: ${utcNow}`);
    throw new Error('Date from response header was not parsed correctly.');
  }
  return utcNow;
};

/**
 * Returns date without time fraction in ISO8601 format.
 * @param date original date with time.
 */
export const getIsoDate = (date: Dayjs | string | Date) => {
  // Use UTC to avoid the problem when only date is provided,
  // without UTC if we provide 2020-01-02 with UTC+1 date will be 2020-01-01T23:00:00.000Z
  const dayjsDate = dayjs.utc(date);
  return dayjsDate.format('YYYY-MM-DD');
};
