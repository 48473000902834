/* eslint no-unused-vars: 0 */
// Enum is connected to localizations, be aware of it if you change the value.
export enum TeleSettingsFilters {
  Empty = 'Empty',
  RecentlySucceededOutgoingSettings = 'RecentlySucceededOutgoingSettings',
  RecentlyProblematicOutgoingSettings = 'RecentlyProblematicOutgoingSettings',
}

// Enum is connected to localizations, be aware of it if you change the value.
export enum DeviceAssignmentFilters {
  Empty = 'Empty',
  RecentlyAssignedDevice = 'RecentlyAssignedDevice',
  PatientsWithDevice = 'PatientsWithDevice',
  PatientsWithoutDevice = 'PatientsWithoutDevice',
}

export enum PatientNoteFilters {
  Empty = 'Empty',
  CreatedOrUpdatedInLast7Days = 'CreatedOrUpdatedInLast7Days',
  CreatedOrUpdatedInLast14Days = 'CreatedOrUpdatedInLast14Days',
  CreatedOrUpdatedInLast30Days = 'CreatedOrUpdatedInLast30Days',
  NotCreatedOrUpdatedInLast7Days = 'NotCreatedOrUpdatedInLast7Days',
  NotCreatedOrUpdatedInLast14Days = 'NotCreatedOrUpdatedInLast14Days',
  NotCreatedOrUpdatedInLast30Days = 'NotCreatedOrUpdatedInLast30Days',
  NoVisibleOrNotDeletedComments = 'NoVisibleOrNotDeletedComments',
}

export type PrismaAppUsageFilter = 'Empty' | 'PatientUsesPrismaApp' | 'PatientDoesntUsePrismaApp';

// String here represents the name of a Questionnaire.
export type QuestionnaireTypeFilter = 'All' | string;

export type QuestionnaireStatusFilter = 'All' | 'Answered' | 'WaitingForAnswer' | 'Expired';

// Enum is connected to localizations, be aware of it if you change the value.
export enum SharingStatusFilters {
  /*
   * Means that the value is not selected.
   * */
  Empty = 'Empty',
  /*
   * All patients who belong to current organisation.
   * */
  Own = 'OwnPatients',
  /*
   * Patients, who are not shared to any organization.
   * */
  Private = 'NotSharedByMeIndividually',
  /*
   * Patients, who are shared to the organisation.
   * */
  SharedWithMe = 'SharedToCurrentOrganisation',
  /*
   * Patients, who are shared by current organisation.
   * */
  SharedByMe = 'SharedByMeIndividually',
}

export enum DeviceCommonFilter {
  AllTypes = 'AllTypes',
  LastReceivedAtByModem = 'LastReceivedAtByModem',
  LastReceivedAtByPatientApp = 'LastReceivedAtByPatientApp',
  LastReceivedAtByUpload = 'LastReceivedAtByUpload',
  TelemonitoringDisabled = 'TelemonitoringDisabled',
  RecentlyChangedDeviceConfig = 'RecentlyChangedDeviceConfig',
}

export enum ProblematicDeviceFilter {
  MissingData = 'MissingData',
  WrongDeviceTime = 'WrongDeviceTime',
  ProblemsWithDeviceConfig = 'ProblemsWithDeviceConfig',
}

export enum OutOfUseDevicesFilter {
  AllDevices = 'AllDevices',
  OutOfUseDevices = 'OutOfUseDevices',
}

export const PatientCategoryFilterKey = 'patientCategory';

export const PatientQuestionnaireFilterKey = 'questionnaireType';
