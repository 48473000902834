import clsx from 'clsx';
import React, { createContext, useContext, useEffect, useRef } from 'react';

import { ShowBusyIndicatorInfo, updateShowBusyIndicator } from '@controls/BusyIndicator/busyIndicatorUtils';

import './BusyIndicator.css';

const BusyIndicationIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' version='1.1'>
    <g>
      <path
        d='M 12,0.0116303 A 11.988276,11.988288 0 0 0 0.0116303,12.000012 H 2.01926 A 9.9805471,9.980557 0 0 1 12,2.019262 9.9805471,9.980557 0 0 1 21.980327,12.000012 9.9805471,9.980557 0 0 1 12,21.98035 9.9805471,9.980557 0 0 1 11.97687,21.97865 v 1.986155 h -0.306452 a 11.988276,11.988288 0 0 0 0.32958,0.02354 A 11.988276,11.988288 0 0 0 23.98837,12.000012 11.988276,11.988288 0 0 0 12,0.0116303 Z'
        style={{ fill: '#a9ca00', stroke: 'none' }}
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          dur='2s'
          repeatCount='indefinite'
          calcMode='spline'
          keySplines='.7 .1 .1 .9;.7 .1 .1 .9;.7 .1 .1 .9'
          from='0 12 12'
          to='180 12 12'
          values='0 12 12;0 12 12;90 12 12;180 12 12'
        ></animateTransform>
      </path>
      <path
        d='M 12,4.0157425 A 7.9844375,7.9844457 0 0 0 4.0157384,12.000012 H 6.0118039 A 5.9883283,5.9883345 0 0 1 12,6.01181 5.9883283,5.9883345 0 0 1 17.988197,12.000012 5.9883283,5.9883345 0 0 1 12,17.988215 a 5.9883283,5.9883345 0 0 1 -0.02313,-0.0017 v 1.996068 A 7.9844375,7.9844457 0 0 0 12,19.984283 7.9844375,7.9844457 0 0 0 19.984262,12.000013 7.9844375,7.9844457 0 0 0 12,4.0157425 Z'
        style={{ fill: '#929292', stroke: 'none' }}
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 12 12'
          to='180 12 12'
          begin='0.15s'
          dur='2s'
          repeatCount='indefinite'
          calcMode='spline'
          keySplines='.7 .1 .1 .9;.7 .1 .1 .9;.7 .1 .1 .9'
          values='0 12 12;0 12 12;90 12 12;180 12 12'
        />
      </path>
      <path
        d='M 12,8.0078769 A 3.9922187,3.9922228 0 0 0 8.0078689,12.000012 H 10.003934 A 1.9961094,1.9961115 0 0 1 12,10.003945 1.9961094,1.9961115 0 0 1 13.996066,12.000012 1.9961094,1.9961115 0 0 1 12,13.99608 1.9961094,1.9961115 0 0 1 11.97687,13.99358 v 1.996893 A 3.9922187,3.9922228 0 0 0 12,15.992173 3.9922187,3.9922228 0 0 0 15.992131,12.000038 3.9922187,3.9922228 0 0 0 12,8.0079023 Z'
        style={{ fill: '#565656', stroke: 'none' }}
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 12 12'
          to='180 12 12'
          begin='0.3s'
          dur='2s'
          repeatCount='indefinite'
          calcMode='spline'
          keySplines='.7 .1 .1 .9;.7 .1 .1 .9;.7 .1 .1 .9'
          values='0 12 12;0 12 12;90 12 12;180 12 12'
        />
      </path>
    </g>
  </svg>
);

export const SmallBusyIndicator: React.FC<{
  className?: string;
}> = ({ className }) => (
  <div className={clsx('busy-indicator', className)}>
    <BusyIndicationIcon />
  </div>
);

/**
 * Render busy indicator in BusyContainer.
 * Now it do not render busy indicator itself but notifies BusyContainer to render Busy indication.
 */
export const BusyIndicator: React.FC<{
  lockUI?: boolean;
  className?: string;
}> = ({ lockUI, className }) => {
  const busyIndicatorContext = useContext(BusyIndicatorContext);
  useEffect(() => {
    const opt = {
      lockUI,
      className,
    };
    busyIndicatorContext.show(opt);
    return () => {
      busyIndicatorContext.hide(opt);
    };
  }, []);

  return null;
};

export const BusyContainer: React.FC<{
  // Is required to ensure a meaningful name is provided hence simplifying debugging
  className: string;
  lockUI?: boolean;
  position: 'top' | 'center';
  children?: React.ReactNode;
}> = (props) => {
  // State behaves unpredictable when is changed by concurrent callbacks,
  // therefore, all work with the state is moved out of callbacks, and the state is stored as Ref.
  const showBusyIndicator = useRef<ShowBusyIndicatorInfo[] | false>(false);
  const actions = useRef<{ action: 'show' | 'hide'; info: ShowBusyIndicatorInfo }[]>([]);

  // Every render is important as it is used for setting the state, so we call re-render manually when it's needed.
  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleShow = (info: ShowBusyIndicatorInfo) => {
    actions.current = [...actions.current, { action: 'show', info }];
    forceUpdate();
  };

  const handleHide = (info: ShowBusyIndicatorInfo) => {
    actions.current = [...actions.current, { action: 'hide', info }];
    forceUpdate();
  };

  const newState = updateShowBusyIndicator(showBusyIndicator.current, actions.current);
  actions.current = [];

  if (newState != showBusyIndicator.current) {
    showBusyIndicator.current = newState;
    forceUpdate();
  }

  return (
    <BusyIndicatorContext.Provider value={{ show: handleShow, hide: handleHide }}>
      <div className={clsx('busy-container', showBusyIndicator.current && 'busy-container__show', props.className)}>
        {props.children}
        {showBusyIndicator.current && (
          <>
            <div
              className={clsx(
                'busy-indicator',
                'busy-container__overlaid',
                {
                  'busy-indicator__show-on-top': props.position === 'top',
                },
                showBusyIndicator.current.map((x) => x.className),
              )}
            >
              <BusyIndicationIcon />
            </div>
            {(props.lockUI || showBusyIndicator.current.some((x) => x.lockUI)) && (
              <div className={clsx('busy-container__lock-ui')} />
            )}
          </>
        )}
      </div>
    </BusyIndicatorContext.Provider>
  );
};

const BusyIndicatorContext = createContext({
  show: (_opt: { lockUI?: boolean; className?: string }) => {},
  hide: (_opt: { lockUI?: boolean; className?: string }) => {},
});
