import { EditableSettingsDto } from '@shared/api/core-api/settings-api';

export const initSettings: EditableSettingsDto = {
  isTwoFactorAuthenticationEnabled: false,
  isDeviceSettingsConfirmationRequiresPassword: false,
  isPicGenerationForbidden: false,
  p5BestCompliance: {
    enabled: false,
    days: 0,
    threshold_Hours: 0,
  },
  complianceGoal: {
    enabled: false,
    numberOfDays: 0,
    calculationPeriodDays: 0,
    usageThreshold_Hours: 0,
  },
  isTelemonitoringEnabled: true,
  logoExists: false,
};
