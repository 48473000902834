import { AxiosRequestConfig } from 'axios';
import url from 'url';

import { BaseAPI, prepareRequestAndFillFormData } from '@shared/api/base-api';

export interface P5ReportBestCompliance {
  enabled: boolean;
  days: number;
  threshold_Hours: number;
}

export interface ComplianceGoalSettings {
  /** Goal calculation enabled/disabled */
  enabled: boolean;
  /** Compliance threshold  */
  usageThreshold_Hours: number;
  /**
   * Number of compliant days in the defined period calculationPeriodDays which should be achieved to meet the goal.
   */
  numberOfDays: number;
  /**
   * Number of days which should be used to search for compliant days.
   */
  calculationPeriodDays: number;
}

/**
 * Dto describing all available settings for an organisation
 * @export
 * @interface Settings
 */
export interface EditableSettingsDto {
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isTwoFactorAuthenticationEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isDeviceSettingsConfirmationRequiresPassword: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isPicGenerationForbidden: boolean;
  p5BestCompliance: P5ReportBestCompliance;
  complianceGoal: ComplianceGoalSettings;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isTelemonitoringEnabled: boolean;

  logoExists: boolean;
}

export const SettingsApi = (baseApi: BaseAPI) => {
  return {
    /**
     * Returns settings which can be edited via WebApp.
     * @returns DTO with settings.
     */
    async getSettings(): Promise<EditableSettingsDto> {
      const { data } = await baseApi.get<EditableSettingsDto>('/api/organisations/settings');
      return data;
    },

    /**
     * Enables the requirement to confirm device configuration changes.
     */
    async activateConfirmSettingsRequired() {
      await baseApi.post('/api/organisations/settings/configurationConfirmationRequirement');
    },

    /**
     * Disables the requirement to confirm device configuration changes.
     */
    async deactivateConfirmSettingsRequired() {
      await baseApi.delete('/api/organisations/settings/configurationConfirmationRequirement');
    },

    /**
     * Enables two factor authentication.
     */
    async activateTwoFactorAuthentication() {
      await baseApi.post('/api/organisations/settings/2fa/activation');
    },

    /**
     * Disables two factor authentication
     */
    async deactivateTwoFactorAuthentication() {
      await baseApi.delete('/api/organisations/settings/2fa/activation');
    },

    async allowReportComplianceGoal() {
      await baseApi.post('/api/organisations/settings/complianceGoal');
    },

    async disableReportComplianceGoal() {
      await baseApi.delete('/api/organisations/settings/complianceGoal');
    },

    async updateComplianceGoalNumberOfDays(
      numberOfDays: number,
      calculationPeriodDays: number,
      options?: AxiosRequestConfig,
    ) {
      const params = url.format({ query: { numberOfDays, calculationPeriodDays } });
      await baseApi.post(`/api/organisations/settings/complianceGoal/days${params}`, undefined, undefined, options);
    },

    async updateComplianceGoalCalculationPeriodDays(days: number, options?: AxiosRequestConfig) {
      const params = url.format({ query: { days } });
      await baseApi.post(
        `/api/organisations/settings/complianceGoal/periodDays${params}`,
        undefined,
        undefined,
        options,
      );
    },

    /**
     * Enables best compliance report.
     */
    async allowReportBestCompliance() {
      await baseApi.post('/api/organisations/settings/p5BestCompliance');
    },

    /**
     * Disables best compliance report.
     */
    async disableReportBestCompliance() {
      await baseApi.delete('/api/organisations/settings/p5BestCompliance');
    },

    /**
     * Updates number of compliance days.
     * @param days
     * @param options
     */
    async updateNumberOfBestComplianceDays(days: number, options?: AxiosRequestConfig) {
      const params = url.format({ query: { days } });
      await baseApi.post(`/api/organisations/settings/p5BestCompliance/days${params}`, undefined, undefined, options);
    },

    /**
     * Updates best compliance usage threshold.
     * @param threshold
     * @param options
     */
    async updateBestComplianceUsageThreshold(threshold: number, options?: AxiosRequestConfig) {
      const params = url.format({ query: { threshold } });
      await baseApi.post(`/api/organisations/settings/p5BestCompliance/threshold${params}`);
    },

    /**
     * Enable telemonitoring for all newly registered P2.8W devices.
     */
    async activateTelemonitoringForOrganisation() {
      await baseApi.post('/api/organisations/settings/telemonitoringEnabled');
    },

    /**
     * Disables telemonitoring for all newly registered P2.8W devices.
     */
    async deactivateTelemonitoringForOrganisation() {
      await baseApi.delete('/api/organisations/settings/telemonitoringEnabled');
    },

    /**
     * Uploads an organisation logo.
     * @param logo Logo file to be uploaded.
     */
    async uploadLogo(logo: File): Promise<void> {
      const localVarAxiosArgs = prepareRequestAndFillFormData(logo, undefined, '/api/organisations/settings/logo');
      const axiosRequestArgs = {
        ...localVarAxiosArgs.options,
        url: localVarAxiosArgs.url,
      };
      await baseApi.request(axiosRequestArgs);
    },

    /**
     * Removes an organisation logo.
     */
    async removeLogo(): Promise<void> {
      await baseApi.delete('/api/organisations/settings/logo');
    },
  };
};

export const SettingsApiFactory = (baseApi: BaseAPI) => SettingsApi(baseApi);
