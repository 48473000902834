import dayjs from 'dayjs';

import { ReportType } from '@shared/api/statistics-gateway-api/report-api';
import { RequestState } from '@shared/constants/request';
import * as ReportsActionTypes from '@shared/redux/actions/report-actions';
import { ReportDateRange } from '@shared/types/ReportTypes';

export interface ReportRange extends ReportDateRange {
  startDate?: Date;
  endDate?: Date;
  trendCurveDates: ReportDateRange[];
}

export interface ReportsState {
  isBusy: boolean;
  patientPrismaCloudId: string | null;
  fullName: string | null;
  validDates: ReportRange | null;
  requestState: RequestState;
  selection: ReportDateRange | null;
  reportType: ReportType;
}

export const initialReportsState = (): ReportsState => ({
  isBusy: true,
  patientPrismaCloudId: null,
  fullName: null,
  requestState: RequestState.NOT_STARTED,
  validDates: null,
  selection: null,
  reportType: ReportType.Detailed,
});

const defaultState: ReportsState = initialReportsState();

export default function reports(
  state: ReportsState = defaultState,
  action: ReportsActionTypes.ReportActions,
): ReportsState {
  switch (action.type) {
    // For performance improvement REPORT_VALID_DATES.REQUEST is done directly from
    // GENERATE_REPORT_POPUP_FROM_CONTEXT so handle also GENERATE_REPORT_POPUP_FROM_CONTEXT
    // as start of REPORT_VALID_DATES
    case ReportsActionTypes.GENERATE_REPORT_POPUP_FROM_CONTEXT:
    case ReportsActionTypes.REPORT_VALID_DATES.REQUEST:
      return {
        ...state,
        patientPrismaCloudId: action.payload.patientPrismaCloudId,
        fullName: action.payload.fullName ?? state.fullName,
        isBusy: true,
        requestState: RequestState.PENDING,
      };

    case ReportsActionTypes.GENERATE_REPORT_POPUP_FROM_PATIENT_DETAILS:
      const period = action.payload.statisticsPeriod ?? null;
      return {
        ...state,
        isBusy: false,
        validDates: period && {
          trendCurveDates: period.trendCurveDates.map((x) => ({
            startDate: dayjs(x.startDate).toDate(),
            endDate: dayjs(x.endDate).toDate(),
          })),
          startDate: dayjs(period.startDate).toDate(),
          endDate: dayjs(period.endDate).toDate(),
        },
        fullName: action.payload.fullName,
        patientPrismaCloudId: action.payload.patientPrismaCloudId,
      };

    case ReportsActionTypes.REPORT_VALID_DATES.SUCCESS:
      const dateRange = action.payload ?? null;
      const validDates = dateRange && {
        trendCurveDates: dateRange.trendCurveDates.map((x) => ({
          startDate: dayjs(x.startDate).toDate(),
          endDate: dayjs(x.endDate).toDate(),
        })),
        startDate: dayjs(dateRange.startDate).toDate(),
        endDate: dayjs(dateRange.endDate).toDate(),
      };

      if (validDates === null) {
        return {
          ...state,
          validDates,
          isBusy: false,
          requestState: RequestState.SUCCESS,
        };
      }

      return {
        ...state,
        validDates,
        isBusy: false,
        requestState: RequestState.SUCCESS,
        selection: {
          ...state.selection,
          endDate: validDates.endDate,
          startDate: validDates.endDate,
        },
      };

    case ReportsActionTypes.REPORT_VALID_DATES.FAILURE:
      return {
        ...state,
        patientPrismaCloudId: null,
        fullName: null,
        isBusy: false,
        requestState: RequestState.FAILED,
      };

    case ReportsActionTypes.GENERATE_REPORT_POPUP_CLOSE:
      return {
        ...defaultState,
      };

    case ReportsActionTypes.UPDATE_RANGE_SELECTION:
      const selectedRange = action.payload.selectedRange;
      return {
        ...state,
        selection: selectedRange,
      };

    case ReportsActionTypes.UPDATE_REPORT_TYPE:
      const reportType: ReportType = action.payload.reportType;
      return {
        ...state,
        reportType,
      };

    default:
      return state;
  }
}
