import { SagaIterator } from '@redux-saga/types';
import dayjs from 'dayjs';
import { call, put } from 'redux-saga/effects';

import { ClientActionsApi } from '@shared/api/core-api/client-actions-api';
import { DateRangeRecipient, ReportDates, ReportsApi, ReportType } from '@shared/api/statistics-gateway-api/report-api';
import {
  hideNotification,
  INFO_ID,
  showNotification,
  showNotificationWithTimeout,
} from '@shared/redux/actions/notification-actions';
import { HideNotificationPayload } from '@shared/redux/actions/payloadTypes/notificationPayloads';
import * as ReportActionTypes from '@shared/redux/actions/report-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { NotificationKinds } from '@shared/redux/state/notification';
import { ReportDateRange } from '@shared/types/ReportTypes';
import { fileDownload } from '@shared/util/Downloader';
import { handleSagaError } from '@shared/util/handleSagaError';
import { getLocalizedDate } from '@shared/util/localizationHelper';
import { getReportNotificationId } from '@shared/util/reportNotificationIdGetter';

const REPORT_POPUP_ID = INFO_ID + '_REPORT_POPUP';

export interface GenerateActionPayload {
  patientPrismaCloudId: string;
  fullName: string;
  dateRange: ReportDateRange;
  uiLocale: string;
  locale: string;
  reportType: ReportType;
}

export interface DownloadActionPayload extends GenerateActionPayload {
  reportContent: ArrayBuffer;
}

export function* generateReportPopupFromPatientDetails(
  action: ReportActionTypes.GenerateReportPopupOpenFromPatientDetails,
) {
  try {
    const { statisticsPeriod } = action.payload;

    // Before start new generate report remove previous warning popup.
    yield put(hideNotification({ id: REPORT_POPUP_ID }));

    if (statisticsPeriod) {
      yield put(ReportActionTypes.REPORT_VALID_DATES.success(statisticsPeriod));
    }

    yield call(ClientActionsApi.trackEvent, 'openReportDialogFromPatientDetails');
    yield put<ReportActionTypes.GenerateReportPopupOpenAction>(ReportActionTypes.generateReportPopupOpen());
  } catch (e) {
    yield handleSagaError(ReportActionTypes.generateReportPopupFailure(e));
  }
}

export function* generateReportPopupFromContext(action: ReportActionTypes.GenerateReportOpenContextAction): any {
  try {
    const { patientPrismaCloudId } = action.payload;

    // Before start new generate report, remove previous warning popup.
    yield put(hideNotification({ id: REPORT_POPUP_ID }));

    yield call(ClientActionsApi.trackEvent, 'openReportDialogFromContextMenu');
    const response: ReportDates = yield call(ReportsApi.getValidDatesForReport, patientPrismaCloudId, {
      params: { recipient: DateRangeRecipient.Report },
    });
    if (response) {
      yield put(ReportActionTypes.REPORT_VALID_DATES.success(response));
      yield put<ReportActionTypes.GenerateReportPopupOpenAction>(ReportActionTypes.generateReportPopupOpen());
    } else {
      const infoPayload = {
        id: REPORT_POPUP_ID,
        resourceKey: 'Report.NoStatisticsData',
        kind: NotificationKinds.Warning,
      };

      yield put(showNotificationWithTimeout(infoPayload));
      yield put(ReportActionTypes.REPORT_VALID_DATES.failure());
    }
  } catch (e) {
    yield handleSagaError(ReportActionTypes.generateReportPopupFailure(e));
  }
}

export function* fetchGenerateReport(action: ReportActionTypes.GenerateReportRequestAction): SagaIterator {
  try {
    const { patientPrismaCloudId, fullName, dateRange, uiLocale, locale, reportType } = action.payload;
    const reportResult = yield call(
      ReportsApi.generateAndGetReport,
      patientPrismaCloudId,
      dayjs(dateRange.startDate),
      dayjs(dateRange.endDate),
      uiLocale,
      locale,
      reportType,
    );
    const downloadPayload: DownloadActionPayload = {
      dateRange,
      fullName,
      uiLocale,
      locale,
      patientPrismaCloudId,
      reportContent: reportResult,
      reportType,
    };

    const successAction = ReportActionTypes.GENERATE_REPORT.success(downloadPayload);
    yield put(successAction);
  } catch (e) {
    const { locale, dateRange, patientPrismaCloudId } = action.payload;
    const id = getReportNotificationId(patientPrismaCloudId, dateRange, locale);

    yield handleSagaError({
      type: ReportActionTypes.GENERATE_REPORT.FAILURE,
      error: e,
      payload: { id },
    });
  }
}

export function* fetchDownloadReport(action: ActionWithPayload<DownloadActionPayload>): SagaIterator {
  try {
    const { locale, dateRange, reportContent, patientPrismaCloudId, fullName } = action.payload;
    const fileName =
      'report_' +
      `${getLastName(fullName)}_` +
      `${getLocalizedDate(dateRange.startDate)}-` +
      `${getLocalizedDate(dateRange.endDate)}_` +
      `${locale}.pdf`;
    const fileContentType = 'application/pdf';
    const isBase64 = false;

    yield call(fileDownload, reportContent, fileName, fileContentType, isBase64);

    const id = getReportNotificationId(patientPrismaCloudId, dateRange, locale);

    const successAction = ReportActionTypes.DOWNLOAD_REPORT.success({ id });
    yield put(successAction);
  } catch (e) {
    const { locale, dateRange, patientPrismaCloudId } = action.payload;
    const id = getReportNotificationId(patientPrismaCloudId, dateRange, locale);

    yield handleSagaError({
      type: ReportActionTypes.DOWNLOAD_REPORT.FAILURE,
      error: e,
      payload: { id },
    });
  }
}

export function* fetchValidDateRangeForPatientReport(
  action: ReportActionTypes.ReportValidDateRequestAction,
): SagaIterator {
  try {
    const { patientPrismaCloudId } = action.payload;
    const response: ReportDates = yield call(ReportsApi.getValidDatesForReport, patientPrismaCloudId, {
      params: { recipient: DateRangeRecipient.Report },
    });

    yield put(ReportActionTypes.REPORT_VALID_DATES.success(response));
  } catch (e) {
    yield handleSagaError({
      type: ReportActionTypes.REPORT_VALID_DATES.FAILURE,
      error: e,
    });
  }
}

export function* fetchReportNotification(action: ActionWithPayload<GenerateActionPayload>): SagaIterator {
  try {
    const { patientPrismaCloudId, fullName, dateRange, locale } = action.payload;
    const id = getReportNotificationId(patientPrismaCloudId, dateRange, locale);
    const payload = {
      id,
      resourceKey: 'Report.Notification',
      kind: NotificationKinds.Loading,
      localizationParams: { patientName: fullName },
    };

    yield put(showNotification(payload));

    yield put({
      type: ReportActionTypes.GENERATE_REPORT_POPUP_CLOSE,
    });
  } catch (e) {
    yield handleSagaError({
      type: ReportActionTypes.GENERATE_REPORT.FAILURE,
      error: e,
    });
  }
}

export function* fetchReportNotificationHide(action: ActionWithPayload<HideNotificationPayload>): SagaIterator {
  const { id } = action.payload;
  const payload = {
    id,
  };

  yield put(hideNotification(payload));
}

function getLastName(fullName: string): string {
  const names = fullName.split(', ');
  return names[0];
}
